import React from 'react';

const BlogIcon = ({ width = 21, height = 21, color = 'white', ...props }) => (
  <svg width={width} height={width} viewBox={`0 0 ${width} ${height}`} fill="none" {...props}>
    <path
      d="M6.26772 2.52848V0.605347L2.97852 3.91066H4.88554C5.253 3.91338 5.60624 3.76878 5.86594 3.50888C6.12584 3.24918 6.27044 2.89594 6.26772 2.52848Z"
      fill={color || 'white'}
    />
    <path
      d="M16.907 0H7.12479V2.52853C7.12688 3.12305 6.89167 3.69392 6.47126 4.11433C6.05085 4.53474 5.47998 4.76995 4.88546 4.76786H2.35693V19.2912C2.35693 20.2339 3.14439 21 4.09256 21H16.9122C17.8552 21 18.6481 20.2339 18.6481 19.2912V1.70884C18.6426 0.766113 17.8552 0 16.907 0ZM5.93555 17.1964H4.35519C4.11851 17.1964 3.92662 17.0045 3.92662 16.7679C3.92662 16.5312 4.11851 16.3393 4.35519 16.3393H5.93555C6.17222 16.3393 6.36412 16.5312 6.36412 16.7679C6.36412 17.0045 6.17222 17.1964 5.93555 17.1964ZM5.93555 12.9107H4.35519C4.11851 12.9107 3.92662 12.7188 3.92662 12.4821C3.92662 12.2455 4.11851 12.0536 4.35519 12.0536H5.93555C6.17222 12.0536 6.36412 12.2455 6.36412 12.4821C6.36412 12.7188 6.17222 12.9107 5.93555 12.9107ZM5.93555 8.625H4.35519C4.11851 8.625 3.92662 8.43311 3.92662 8.19643C3.92662 7.95975 4.11851 7.76786 4.35519 7.76786H5.93555C6.17222 7.76786 6.36412 7.95975 6.36412 8.19643C6.36412 8.43311 6.17222 8.625 5.93555 8.625ZM16.6498 17.1964H8.09975C7.86307 17.1964 7.67118 17.0045 7.67118 16.7679C7.67118 16.5312 7.86307 16.3393 8.09975 16.3393H16.6444C16.8809 16.3399 17.0723 16.5314 17.073 16.7679C17.0738 16.8809 17.0299 16.9897 16.9503 17.07C16.871 17.1504 16.7628 17.1958 16.6498 17.1964ZM16.6498 12.9107H8.09975C7.86307 12.9107 7.67118 12.7188 7.67118 12.4821C7.67118 12.2455 7.86307 12.0536 8.09975 12.0536H16.6444C16.8809 12.0542 17.0723 12.2457 17.073 12.4821C17.0738 12.5951 17.0299 12.704 16.9503 12.7843C16.871 12.8647 16.7628 12.9101 16.6498 12.9107ZM16.6498 8.625H8.09975C7.86307 8.625 7.67118 8.43311 7.67118 8.19643C7.67118 7.95975 7.86307 7.76786 8.09975 7.76786H16.6444C16.8809 7.76849 17.0723 7.95996 17.073 8.19643C17.0738 8.30943 17.0299 8.41825 16.9503 8.4986C16.871 8.57896 16.7628 8.62437 16.6498 8.625Z"
      fill={color || 'white'}
    />
  </svg>
);

export default BlogIcon;
