import React from 'react';
import { graphql } from 'gatsby';
import BookmarksContent from 'components/Page/Toolbox/BookmarksContent';
import ToolboxLayout from '../../layouts/toolboxLayout';

const Bookmarks = (props) => {
  const { seo: seoContent } = props.data.contentfulContentHub;

  return (
    <ToolboxLayout footer={null} header={null} contentfulSeo={seoContent}>
      <BookmarksContent prevPath={props.location?.state?.prevPath || '/toolbox'} />
    </ToolboxLayout>
  );
};

export default Bookmarks;

export const BookmarkssPage = graphql`
  query BookmarksPageQuery {
    contentfulContentHub(internalName: { eq: "Toolbox" }) {
      seo {
        ...Seo
      }
    }
  }
`;
