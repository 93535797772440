import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';
import { Col } from 'components/Core/Grid';

export const ToolboxBookmarksLayout = styled(SectionLayout)`
  background-color: rgba(19, 19, 19);
  color: white;
  .section-layout--inner-container {
    padding-top: 60px;
  }
  height: 100%;
  min-height: 100vh;
  a:visited {
    color: white;
  }
  .card-container {
    margin-top: 0px;
  }
`;

export const TookboxBookmarksTitle = styled.h4`
  color: inherit;
  font-weight: var(--font-weight-900);
`;

export const CloseButton = styled.h5`
  color: inherit;
  transform: scale(2, 1.5);
  display: inline-block;
  font-weight: normal;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    font-size: var(--font-size-13);
    margin-right: 5px;
  }
`;

export const BookmarksCol = styled(Col)`
  margin-top: 15px;
  .section-layout--inner-container,
  .st-cta-cards-section .card-row-container {
    margin-top: 0;
    padding: 0;
  }

  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

export const RightCol = styled(Col)`
  text-align: right;
  a {
    color: var(--white);
  }
`;
