import React from 'react';
import ContentfulCardsBlock from 'components/Contentful/ContentfulCardsBlock';
import useToolboxImages from 'hooks/useToolboxImages';

const BookmarkCards = ({ data = [] }) => {
  const pageImages = useToolboxImages();
  const cardsBlock = {
    bgColor: 'Transparent',
    cardType: 'Image',
    cards: (data || []).map(({ title, subTitle, image, url, type }) => ({
      cover: {
        gatsbyImageData: image?.images ? image : '',
        file: { url: image?.images ? '' : image },
      },
      title,
      subTitle,
      ctaLink: url,
      ctaText: 'View',
      icon: pageImages[`${type}-mark-white`]?.image?.file?.url,
      category: type,
      categories: type,
      type,
    })),
  };
  return <ContentfulCardsBlock cols={3} {...cardsBlock} />;
};

export default BookmarkCards;
