import React from 'react';

const WebinarIcon = ({ width = 24, height = 24, color = 'white', ...props }) => (
  <svg width={width} height={width} viewBox={`0 0 ${width} ${height}`} fill="none" {...props}>
    <path
      d="M12.1406 0C7.10063 0 3 4.19438 3 9.23438C3 14.1752 3 12.7402 3 17.6719C3 18.835 3.94622 19.7812 5.10938 19.7812C6.27253 19.7812 7.21875 18.835 7.21875 17.6719V10.6406C7.21875 9.47747 6.27253 8.53125 5.10938 8.53125C4.87247 8.53125 4.64855 8.57925 4.43578 8.65158C4.73569 4.65881 8.07258 1.40625 12.1406 1.40625C16.2087 1.40625 19.5456 4.65881 19.8455 8.65158C19.6327 8.57925 19.4088 8.53125 19.1719 8.53125C18.0087 8.53125 17.0625 9.47747 17.0625 10.6406V17.6719C17.0625 18.5873 17.6521 19.3605 18.4688 19.6517V20.4844C18.4688 20.8723 18.1536 21.1875 17.7656 21.1875H14.1205C13.8293 20.3708 13.0561 19.7812 12.1406 19.7812C10.9775 19.7812 10.0312 20.7275 10.0312 21.8906C10.0312 23.0538 10.9775 24 12.1406 24C13.0561 24 13.8293 23.4104 14.1205 22.5938H17.7656C18.9288 22.5938 19.875 21.6475 19.875 20.4844V19.6517C20.6917 19.3605 21.2812 18.5873 21.2812 17.6719C21.2812 12.7402 21.2812 14.1751 21.2812 9.23438C21.2812 4.19438 17.1806 0 12.1406 0Z"
      fill={color || 'white'}
    />
    <path
      d="M0 12.0469V16.2656C0 17.1811 0.636469 17.9543 1.45312 18.2455V10.067C0.636469 10.3583 0 11.1314 0 12.0469Z"
      fill={color || 'white'}
    />
    <path
      d="M22.5469 10.067V18.2455C23.3635 17.9543 24 17.1811 24 16.2656V12.0469C24 11.1314 23.3635 10.3583 22.5469 10.067Z"
      fill={color || 'white'}
    />
  </svg>
);

export default WebinarIcon;
