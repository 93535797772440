import React from 'react';

const ToolsIcon = ({ width = 22, height = 22, color = 'white', ...props }) => (
  <svg width={width} height={width} viewBox={`0 0 ${width} ${height}`} fill="none" {...props}>
    <path
      d="M4.35728 8.43349C4.79936 8.43349 5.23365 8.36425 5.65203 8.23086L8.1387 10.7158L10.862 7.99311L8.37646 5.50543C8.85559 4.00821 8.46191 2.34919 7.34524 1.23292C6.55032 0.437757 5.49209 0 4.36848 0C3.74067 0 3.11117 0.142265 2.54635 0.411589C2.46113 0.452192 2.40146 0.531392 2.38548 0.624254C2.36997 0.717116 2.39984 0.811368 2.46615 0.878294L5.08251 3.49527C5.27556 3.68817 5.38209 3.94429 5.38209 4.21617C5.38209 4.48873 5.27541 4.74439 5.08251 4.9373C4.69817 5.32279 4.02621 5.32279 3.64117 4.93629L1.02389 2.32001C0.95681 2.25293 0.860397 2.22352 0.770159 2.23912C0.677065 2.2551 0.598097 2.31476 0.557494 2.39999C-0.211187 4.01083 0.11827 5.93987 1.3789 7.19957C2.17429 7.99496 3.23198 8.43349 4.35728 8.43349Z"
      fill={color || 'white'}
    />
    <path
      d="M17.6443 13.2733C17.2013 13.2733 16.7663 13.343 16.3476 13.4767L13.5972 10.7261L10.8721 13.4507L13.6233 16.2019C13.1452 17.6986 13.5385 19.3572 14.6545 20.4741C15.4497 21.2693 16.5079 21.7071 17.6314 21.7071C18.2595 21.7071 18.889 21.5646 19.4538 21.2953C19.539 21.2548 19.5987 21.1755 19.6146 21.0827C19.6306 20.9898 19.6003 20.8955 19.534 20.8286L16.9177 18.2111C16.725 18.0187 16.6186 17.763 16.6183 17.4911C16.6183 17.2188 16.7248 16.9631 16.9182 16.77C17.3037 16.3842 17.9742 16.3844 18.359 16.7702L20.9758 19.3865C21.0426 19.4531 21.1359 19.4828 21.2298 19.4674C21.3227 19.4512 21.402 19.3918 21.4425 19.3065C22.2112 17.6959 21.8819 15.7671 20.6213 14.5072C19.8261 13.7119 18.7687 13.2733 17.6443 13.2733Z"
      fill={color || 'white'}
    />
    <path
      d="M16.5245 7.07022C16.5245 7.07022 16.9797 7.46892 17.355 7.28265C17.7792 7.06922 18.9742 8.24733 19.3554 8.52298C19.3567 8.52391 19.3593 8.52391 19.3638 8.52607L19.1553 8.73541C18.8801 9.00751 18.8803 9.45183 19.1546 9.7244C19.4291 9.99774 19.8705 9.99751 20.1451 9.7244L21.7953 8.07634C22.068 7.80231 22.0682 7.35877 21.7948 7.0852C21.5225 6.81255 21.0783 6.81255 20.8049 7.08543L20.5301 7.36C20.3024 7.04637 19.924 6.51181 19.972 6.33473C20.1733 5.55918 18.5922 3.72725 18.5922 3.72725C14.6656 -0.198591 12.0428 -0.109357 10.7951 0.319678C10.2943 0.491971 10.3871 0.601121 10.9073 0.692748C14.6929 1.35351 15.1624 3.58592 14.5207 4.44723C14.2739 4.77977 14.6339 5.18125 14.6339 5.18125L14.7692 5.31564L8.37744 11.7062C8.46413 11.7585 8.54835 11.8179 8.6226 11.8924L9.69782 12.9666C9.77084 13.0399 9.83051 13.1235 9.88231 13.2101L16.2741 6.81927L16.5245 7.07022Z"
      fill={color || 'white'}
    />
    <path
      d="M8.1926 12.3222C7.9567 12.086 7.55522 12.0708 7.30288 12.29L0.199724 18.6581C-0.0537756 18.877 -0.0679017 19.2496 0.168461 19.486L2.10321 21.4194C2.33973 21.6557 2.71164 21.6425 2.93032 21.3904L9.30039 14.2852C9.5183 14.0331 9.50448 13.633 9.26789 13.3969L8.1926 12.3222Z"
      fill={color || 'white'}
    />
  </svg>
);

export default ToolsIcon;
