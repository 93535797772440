import React from 'react';

const PodcastIcon = ({ width = 22, height = 22, color = 'white', ...props }) => (
  <svg width={width} height={width} viewBox={`0 0 ${width} ${height}`} fill="none" {...props}>
    <path
      d="M10.7771 13.5807C9.24476 13.5807 8.02714 14.3819 8.19523 15.3199L8.89951 22.8659C8.89951 23.4923 9.74014 24 10.7771 24C11.8141 24 12.6548 23.4922 12.6548 22.8659L13.3591 15.3199C13.5268 14.3802 12.3075 13.5807 10.7771 13.5807Z"
      fill={color || 'white'}
    />
    <path
      d="M10.777 0C4.83459 0 0 4.83459 0 10.7771C0 15.5138 3.07305 19.5436 7.32897 20.9852L7.20175 19.6221C3.7068 18.2042 1.2346 14.7749 1.2346 10.7771C1.2346 5.51528 5.51534 1.2346 10.777 1.2346C16.0389 1.2346 20.3197 5.51534 20.3197 10.7771C20.3197 14.7748 17.8475 18.2041 14.3525 19.6221L14.2252 20.9852C18.4813 19.5436 21.5543 15.5138 21.5543 10.777C21.5543 4.83459 16.7196 0 10.777 0Z"
      fill={color || 'white'}
    />
    <path
      d="M10.777 3.49817C6.76332 3.49817 3.49805 6.76351 3.49805 10.7772C3.49805 13.3893 4.88174 15.6835 6.95397 16.9679L6.81645 15.495C6.80794 15.4377 6.80469 15.3806 6.8004 15.3235C5.53443 14.2147 4.73265 12.5883 4.73265 10.7772C4.73265 7.44426 7.44402 4.73277 10.777 4.73277C14.11 4.73277 16.8215 7.44426 16.8215 10.7772C16.8215 12.5883 16.0197 14.2148 14.7536 15.3235C14.7493 15.3806 14.7461 15.4376 14.7376 15.4947L14.6001 16.9678C16.6723 15.6834 18.0561 13.3893 18.0561 10.7772C18.0561 6.76351 14.7907 3.49817 10.777 3.49817Z"
      fill={color || 'white'}
    />
  </svg>
);

export default PodcastIcon;
