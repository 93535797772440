import React from 'react';
import HyperLink from 'components/Core/HyperLink';
import BookmarkIcon from 'components/Brand/Icons/Toolbox/BookmarkIcon';
import BlogIcon from 'components/Brand/Icons/Toolbox/BlogIcon';
import WebinarIcon from 'components/Brand/Icons/Toolbox/WebinarIcon';
import PodcastIcon from 'components/Brand/Icons/Toolbox/PodcastIcon';
import ToolsIcon from 'components/Brand/Icons/Toolbox/ToolsIcon';
import DownloadIcon from 'components/Brand/Icons/Toolbox/DownloadIcon';
import { MobileSelectMenu, StyledSettingsMenu } from './styles';

const BookmarksMenuItem = ({ children, onClick, selected }) => (
  <h6 className={selected === children.value ? 'selected' : ''}>
    <div>
      <children.Icon height="26" width="26" />
      <HyperLink onClick={() => onClick(children.value)}>{children.text}</HyperLink>
    </div>
  </h6>
);

const BookmarksMenu = ({ selected = '', onClick }) => {
  const items = [
    { text: 'All Bookmarks', Icon: BookmarkIcon, value: '' },
    { text: 'Webinars', Icon: WebinarIcon, value: 'webinar' },
    { text: 'Guides', Icon: BlogIcon, value: 'guide' },
    { text: 'Tools', Icon: ToolsIcon, value: 'tool' },
    { text: 'Podcasts', Icon: PodcastIcon, value: 'podcast' },
    { text: 'Articles', Icon: BlogIcon, value: 'article' },
    { text: 'Downloads', Icon: DownloadIcon, value: 'download' },
  ];

  return (
    <>
      <MobileSelectMenu
        placeholder="All Bookmarks"
        onSelect={onClick}
        SelectProps={{
          MenuProps: {
            PopoverClasses: {
              root: 'bookmarks-menu-select max-md',
            },
          },
        }}
        options={items.map((i) => {
          return { label: i.text, value: i.value };
        })}
      />

      <StyledSettingsMenu className="min-md">
        {items.map((item) => (
          <BookmarksMenuItem onClick={onClick} selected={selected}>
            {item}
          </BookmarksMenuItem>
        ))}
      </StyledSettingsMenu>
    </>
  );
};

export default BookmarksMenu;
