import React from 'react';

const DownloadIcon = ({ width = 23, height = 26, color = 'white', ...props }) => (
  <svg width="33" height="40" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.3832 14.2257H32.5609L16.5 30.2866L0.439117 14.2257H9.61676V0.459229H23.3832V14.2257Z"
      fill={color}
    />
    <path d="M32.5609 39.7449H0.439117V35.1561H32.5609V39.7449Z" fill={color} />
  </svg>
);

export default DownloadIcon;
