import styled from 'styled-components';
import Select from 'components/Core/Select';

export const StyledSettingsMenu = styled.div`
  display: flex;
  color: white;
  padding: 34px 0 4px 0;

  .bookmark-icon {
    width: 26px;
  }
  > h6 {
    margin: 0 15px 30px 0;
    font-weight: 450;
    width: max-content;
    &:hover {
      cursor: pointer;
    }
    div {
      display: flex;
      margin-right: 20px;
      align-items: center;
    }
    svg {
      margin-right: 10px;
      height: 26px;
    }
    a {
      color: white;
    }
    &.selected {
      a {
        color: var(--titan-blue-3);
      }
      svg path {
        fill: var(--titan-blue-3);
      }
    }

    @media (max-width: 1000px) {
      font-size: var(--font-size-18);

      > h6 {
        margin: 0 10px 30px 0;
      }

      svg {
        display: none;
      }
    }
  }
`;

export const MobileSelectMenu = styled(Select)`
  margin: 10px 0;
  width: 100%;
`;
